.RSTable {
	background-color: white;
}

.RSTable th,
.RSTable td {
	text-align: left;
}

.RSTable .deleteButton {
	background: white;
	padding: .25em;
	&:hover {
		cursor: pointer;
	}
}
.RSTable .deleteIcon {
	color: red !important;
	font-size: 1.4rem;
}