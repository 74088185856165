.DropdownToggle {
	outline: none;
	border: none;
	font-weight: 400;
	background-color: white !important;
	color: var(--gray-700) !important;
	font-size: 12px;
}
.DropdownToggle:hover,
.DropdownToggle:active,
.DropdownToggle:focus {
	background-color: white !important;
	color: black !important;
}

.DropdownMenu {
	width: 100%;
}

.link {
	color: var(--gray-900);
}

.DropdownItemHeader {
	text-transform: uppercase;
	font-size: 12px;
}

.portrait {
	padding-right: 1em;
	width: 50px;
	height: 38px;
}
