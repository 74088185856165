html,
body {
  height: 100%;
}

.App {
  height: 100%;
  text-align: left;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* overwrite amplify standard settings for theming */
:root {
  /** font settings */
  --amplify-font-family: var(--font-family-sans-serif);
  --amplify-text-xxs: 0.64rem;
  --amplify-text-xs: 0.8rem;
  --amplify-text-sm:  1rem;
  --amplify-text-md: 1.25rem;
  --amplify-text-lg: 1.563rem;
  --amplify-text-xl: 1.953rem;
  --amplify-text-xxl: 2.441rem;

  /** primary button bgs, links, .. */
  --amplify-primary-color: var(--primary);
  /** hover .. */
  --amplify-primary-tint: var(--primary-dark1);
  /** active state */
  --amplify-primary-shade: var(--primary-dark1);

  /** other possible variables that may be overridden, including their defaults */
  /* --amplify-primary-color #ff9900 */
  /* --amplify-primary-contrast var(–amplify-white) */
  /* --amplify-primary-tint #ffac31 */
  /* --amplify-primary-shade #e88b01 */
  /* --amplify-secondary-color #152939 */
  /* --amplify-secondary-contrast var(–amplify-white) */
  /* --amplify-secondary-tint #31465f */
  /* --amplify-secondary-shade #1F2A37 */
  /* --amplify-tertiary-color #5d8aff */
  /* --amplify-tertiary-contrast var(–amplify-white) */
  /* --amplify-tertiary-tint #7da1ff */
  /* --amplify-tertiary-shade #537BE5 */
  /* --amplify-grey #828282 */
  /* --amplify-light-grey #c4c4c4 */
  /* --amplify-white #ffffff */
  /* --amplify-red #dd3f5b*/

}

amplify-sign-in {
  --container-height: 10vh !important;
}
amplify-sign-out {
  width: 100%;
}

amplify-authenticator {
  --container-height: auto;
}
.auth-container {
  min-height: 1vh !important;
}

/* overwrite multiselect styles */
#multiselectContainerReact {
  background-color: white !important;
}

.optionContainer li {
  border-bottom: 1px solid var(--amplify-light-grey);
}
.optionContainer li.highlight {
  background: white !important;
  color: black !important;
}
.optionContainer li.highlight:hover {
  color: white !important;
}

.optionContainer li:hover {
  background-color: red !important;
}

span.chip {
  background-color: var(--light) !important;
  color: var(--primary) !important;
  border-radius: 0;
}

span.chip i {
  font-size: 1.3em !important;
}

/* CKEditor */
.ck-editor__editable_inline {
  min-height: 150px;
}

.jobActionDropDown .dropdown-menu.dropdown-menu-right.show {
  display: flex;
  justify-content: space-evenly;
}

body .table td { vertical-align: middle;}

.nav-item:hover {
  cursor: pointer;
}